<template>
  <div class="">
    <div class="form-row">
      <div class="form-group mr-2">
        <button
          type="button"
          class="btn btn-success"
          @click="kioskLogin">
          Login
        </button>
      </div>

      <sgv-input-text
        placeholder="UID"
        v-model="uid"
        :validations="[
          {text: 'required!', value: $v.uid.$dirty && $v.uid.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <button
        type="button"
        class="btn btn-danger"
        @click="kioskLogout">
        Logout
      </button>
    </div>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  KIOSK_LOGIN,
  KIOSK_LOGOUT
} from './graph'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      uid: ''
    }
  },
  validations: {
    uid: {required},
  },
  methods: {
    kioskLogin () {
      this.$v.uid.$touch()
      if (this.$v.uid.$invalid) return

      this.$apollo.mutate({
        mutation: KIOSK_LOGIN(this.templateType),
        variables: {
          userType: this.userType,
          uid: this.uid,
          userId: this.userId
        }
      })
      .then((res) => {
        if (res.data.login === this.uid) {
          this.$toasted.global.success("ลงชื่อเข้าใช้สำเร็จ")
        }
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    kioskLogout () {
      this.$apollo.mutate({
        mutation: KIOSK_LOGOUT(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลงชื่อออกสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.form-inline > .form-group {
    vertical-align: top;
}
</style>
