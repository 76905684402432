<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">
      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>
        </div>

        <div class="form-row">
          <DetailFormKioskDeviceInput
            :disabled="$auth.disabled(method)"
            :templateType="templateType"
            :userType="userType"
            class="col-12"
            select="id"
            label="Kiosk"
            v-model="formData.kioskDeviceId">
          </DetailFormKioskDeviceInput>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              :disabled="$auth.disabled(method)"
              label="isActive"
              inline
              v-model="formData.isActive">
            </sgv-input-check>
          </div>
        </div>
      </div>
    </sgv-form>

    <div class="row" v-if="userId > 0">
      <DetailGroupList
        :userId="userId"
        :method="method"
        :formData="formData"
        :templateType="templateType"
        :userType="userType"
        class="col-12">
      </DetailGroupList>

      <DetailLogin
        v-if="$auth.hasRole(`user:${userType}:add`)"
        :userId="userId"
        :templateType="templateType"
        :userType="userType"
        class="mt-3 col-12">
      </DetailLogin>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  DETAIL_USER,
  CREATE_USER,
  UPDATE_USER,
  DESTROY_USER,
} from './graph'
import DetailGroupList from './DetailGroupList.vue'
import DetailFormKioskDeviceInput from './DetailFormKioskDeviceInput.vue'
import DetailLogin from './DetailLogin.vue'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    userType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      listView: `User${this.$form.capitalize(this.userType)}List`,
      formData: {
        name: '',
        kioskDeviceId: null,
        isActive: true,
        groups: []
      },
      method: 'info'
    }
  },
  validations: {
    formData: {
      name: {required},
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData, disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit', func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`user:${this.userType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`user:${this.userType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_USER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.user)
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return  {
        name: v.name,
        kioskDeviceId: v.kioskDeviceId,
        isActive: v.isActive
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_USER(this.templateType),
        variables: {
          userType: this.userType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, userId: res.data.createUser.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_USER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateUser)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_USER(this.templateType),
        variables: {
          userType: this.userType,
          userId: this.userId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.userId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailGroupList,
    DetailFormKioskDeviceInput,
    DetailLogin
  }
}
</script>

<style lang="css" scoped>
</style>
