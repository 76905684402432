import gql from 'graphql-tag'

const detailResponse = `
  id name isActive
  kioskDeviceId
  groups {id code name}
`

export const LIST_USER = (templateType) => gql`query LIST_USER ($userType: String!, $q: FilterInput) {
  users: listUser${templateType} (userType: $userType, q: $q) {
    id name isActive
  }
}`

export const DETAIL_USER = (templateType) => gql`query DETAIL_USER ($userType: String!, $userId: Int!) {
  user: detailUser${templateType} (userType: $userType, userId: $userId) {${detailResponse}}
}`

export const CREATE_USER = (templateType) => gql`mutation CREATE_USER ($userType: String!, $input: User${templateType}Input!) {
  createUser: createUser${templateType} (userType: $userType, input: $input) {${detailResponse}}
}`

export const UPDATE_USER = (templateType) => gql`mutation UPDATE_USER ($userType: String!, $userId: Int!, $input: User${templateType}Input!) {
  updateUser: updateUser${templateType} (userType: $userType, userId: $userId, input: $input) {${detailResponse}}
}`

export const DESTROY_USER = (templateType) => gql`mutation DESTROY_USER ($userType: String!, $userId: Int!) {
  destroyUser: destroyUser${templateType} (userType: $userType, userId: $userId) {id}
}`

export const CREATE_GROUP_USER = (templateType) => gql`mutation CREATE_GROUP_USER ($userType: String!, $groupId: Int!, $userId: Int!) {
  createGroupUser: createGroupUser${templateType} (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const DESTROY_GROUP_USER = (templateType) => gql`mutation DESTROY_GROUP_USER ($userType: String!, $groupId: Int!, $userId: Int!) {
  destroyGroupUser: destroyGroupUser${templateType} (userType: $userType, groupId: $groupId, userId: $userId) {
    groupId userId
  }
}`

export const RESET_USERNAME = (templateType) => gql`mutation RESET_USERNAME ($userType: String!, $userId: Int!, $username: String!, $password: String!) {
  resetUserUsername: resetUser${templateType}Username (userType: $userType, userId: $userId, username: $username, password: $password)
}`

export const KIOSK_LOGIN = (templateType) => gql`mutation KIOSK_LOGIN ($userType: String!, $userId: Int!, $uid: String!) {
  login: loginUser${templateType} (userType: $userType, userId: $userId, uid: $uid)
}`

export const KIOSK_LOGOUT = (templateType) => gql`mutation KIOSK_LOGOUT ($userType: String!, $userId: Int!) {
  logout: logoutUser${templateType} (userType: $userType, userId: $userId)
}`

export const LIST_GROUP = (templateType) => gql`query LIST_GROUP ($userType: String!, $q: FilterInput) {
  groups: listUser${templateType}DropdownGroup (userType: $userType, q: $q) {
    id code name
  }
}`

export const DROPDOWN_KIOSK_DEVICES = (templateType) => gql`query DROPDOWN_KIOSK_DEVICES ($userType: String!, $q: FilterInput) {
  kioskDevices: listUser${templateType}DropdownKioskDevice (userType: $userType, q: $q) {
    id type code name
  }
}`
